import type { TableColumnType, TablePaginationConfig } from 'ant-design-vue'

interface FetchProps {
  apiFun: (params: any) => Promise<any>
  columns: TableColumnType[]
  needPaging?: boolean
}

export function usePageFetch(props: FetchProps) {
  // const { apiFun, columns, needPaging } = props
  const fetchParam = ref({ size: 20, current: 1 })
  const state = reactive<any>({
    data: [],
    columns: props.columns,
    config: { total: 0, loading: false, isBorder: false, isSerialNo: true, isSelection: true, isOperate: true },
    pagination: props.needPaging ? { position: ['bottomLeft'], pageSize: 20, pageSizeOptions: ['20', '30', '40', '50'], total: 0, current: 1, showTotal: (total: any) => `共${total}条` } : false,
  })

  function refresh() {
    state.config.loading = true

    return props.apiFun(fetchParam.value).then((data: any) => {
      const { records, current, size, total } = data
      state.data = records
      state.config.total = total
      const pagination = state.pagination as TablePaginationConfig
      pagination.current = current
      pagination.pageSize = size
      pagination.total = total

      return data
    }, (error) => {
      state.config.loading = false
      return Promise.reject(error)
    }).finally(() => {
      state.config.loading = false
    })
  }

  function onPageChange(current: number, pageSize: number) {
    fetchParam.value.current = current
    fetchParam.value.size = pageSize
    refresh()
  }

  onMounted(refresh)

  function onSearch(form: any) {
    fetchParam.value = { ...form, current: 1, size: (state.pagination as TablePaginationConfig).pageSize ?? 20 }
    refresh()
  }

  return { refresh, onPageChange, onSearch, state }
}
